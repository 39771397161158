import Default from '../../../common/Themes/Default/Theme';
import { sectionHeadingAlignment, sectionHeadingColor, sectionHeadingSize, sectionHeadingHR } from '../../../common/utils/themeOverrides';
import { getMenuHeadingStyle } from '../../../common/utils/legacyOverrides';
import * as boldIcons from '../../../common/IconPacks/boldOutline';
import * as socialIconPack from '../../../common/IconPacks/SocialIcons/defaultSocialIconPack';
import { FILL, FIT, INSET, BLUR, LEGACY_BLUR } from '../../../common/constants/headerTreatments';
import themeConfig from '../themeConfig';
import { WIDGETS_WITH_CIRCULAR_IMAGES } from '../../../common/constants';

const typographyShared1 = {
  style: {
    font: 'alternate',
    color: 'highlight',
    fontSize: 'xlarge',
    fontWeight: 'normal',
    letterSpacing: 'normal',
    textTransform: 'none'
  }
};

class Theme9 extends Default {

  static config = themeConfig;

  static get displayName() {
    return 'Theme9';
  }

  static getMutatorDefaultProps(widgetType, widgetPreset) {
    const defaultProps = super.getMutatorDefaultProps(widgetType, widgetPreset);
    const enableCircularImage = WIDGETS_WITH_CIRCULAR_IMAGES[widgetPreset] || defaultProps.enableCircularImage;

    return widgetType === 'HEADER' ? {
      ...defaultProps,
      hasLogoAlign: true,
      headerTreatmentsConfig: {
        ...defaultProps.headerTreatmentsConfig,
        imageTreatments: {
          [FILL]: 'none',
          [FIT]: 'none',
          [INSET]: 'category-solid',
          [BLUR]: 'none',
          [LEGACY_BLUR]: 'category-overlay'
        }
      }
    } : {
      ...defaultProps,
      enableCircularImage
    };
  }

  constructor() {
    super();
    this.mappedValues = {
      ...this.mappedValues,
      typographyOverrides: {
        LogoAlpha: {
          ...typographyShared1
        },
        HeadingAlpha: {
          style: {
            font: 'primary',
            color: 'highContrast',
            fontSize: 'xxlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        HeadingBeta: {
          style: {
            font: 'primary',
            color: 'highlight',
            fontSize: 'xxlarge',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        HeadingGamma: {
          ...typographyShared1
        },
        NavAlpha: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'medium',
            fontWeight: 'bold',
            letterSpacing: '0.063em',
            textTransform: 'uppercase'
          },
          active: {
            style: {
              'color': 'highContrast',
              'borderBottom': '1px solid',
              'borderColor': 'primary',
              ':hover': {
                color: 'highlightHover'
              }
            }
          }
        },
        SubNavAlpha: {
          style: {
            font: 'alternate',
            color: 'section',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        NavBeta: {
          style: {
            font: 'alternate',
            color: 'highContrast',
            fontSize: 'large',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        SubNavBeta: {
          style: {
            font: 'alternate',
            color: 'section',
            fontSize: 'medium',
            fontWeight: 'normal',
            letterSpacing: 'normal',
            textTransform: 'none'
          }
        },
        ButtonAlpha: {
          style: {
            font: 'alternate',
            fontWeight: 'bold',
            letterSpacing: '0.063em',
            textTransform: 'uppercase'
          }
        }
      }
    };
  }

  /* Theme Utilities */

  getSection(inputSection) {
    if (inputSection === 'default') {
      return 'alt';
    } else if (inputSection === 'overlay') {
      return 'overlay';
    }
    return 'default';
  }

  /* Elements - Text & Headings */

  Heading(props) {
    const { tag } = props;
    const { widgetType, widgetPreset } = this.base;

    return super.Heading(this.merge({
      style: getMenuHeadingStyle(tag, widgetType, widgetPreset)
    }, props));
  }

  HeadingMajor(props) {
    return super.HeadingMajor(this.merge({ typography: 'HeadingAlpha' }, props));
  }

  HeroText(props) {
    return super.SubTagline(props);
  }

  NavLinkActive(props) {
    return super.NavLinkActive(this.merge({
      style: {
        marginBottom: '-1px'
      }
    }, props));
  }

  SectionHeading({ layout = 'full', ...props }) {
    const base = this.base;
    const overrides = this.merge({}, sectionHeadingAlignment(base), sectionHeadingColor(base),
      sectionHeadingSize(base), sectionHeadingHR(base));
    return super.SectionHeading(this.merge({
      style: {
        // Static
        'textAlign': 'center',
        '@md': {
          textAlign: layout === 'full' ? 'center' : 'left'
        }
      }
    }, overrides, props));
  }

  MediaObjectBackground(props) {
    return super.MediaObjectBackground(this.merge({
      style: {
        borderRadius: '0px'
      }
    }, props));
  }

  FooterDetails(props) {
    return super.FooterDetails(this.merge({ typography: 'BodyAlpha' }, props));
  }

  PromoBannerText(props) {
    return super.PromoBannerText(this.merge({ featured: true }, props));
  }

  /* Elements - Icons */

  Icon(props) {
    return super.Icon(this.merge({
      iconPack: { ...boldIcons, ...socialIconPack }
    }, props));
  }

  /* Elements - Forms */

  sharedInputStyles = {
    borderColor: 'input',
    borderBottomWidth: 'medium',
    borderRadius: '0',
    borderStyle: 'solid',
    backgroundColor: 'section'
  };

  Input({ section, ...props }) {
    return super.Input(this.merge({
      // Need to get this from context ...
      section: this.getSection(section || this.base.section),
      style: {
        paddingHorizontal: 'small',
        paddingTop: 'small',
        paddingBottom: '11px',
        ...this.sharedInputStyles
      }
    }, props));
  }

  InputTextArea(props) {
    return super.InputTextArea(this.merge({
      rows: 5
    }, props));
  }

  InputSelect({ section, ...props }) {
    return super.InputSelect(this.merge({
      section: this.getSection(section || this.base.section),
      style: {
        ...this.sharedInputStyles,
        ['@xs-only']: {
          fontSize: 'medium' // 16px
        }
      }
    }, props));
  }

  InputSelectElement(props) {
    return super.InputSelectElement(this.merge({
      style: {
        paddingHorizontal: 'small',
        paddingTop: 'small',
        // Compensate for border width
        paddingBottom: '11px',
        ['@xs-only']: {
          fontSize: 'medium' // 16px
        }
      }
    }, props));
  }

  /* Elements - Table */
  Table(props) {
    return super.Table(this.merge({
      style: {
        borderRadius: 0
      }
    }, props));
  }

  ContentCard(props) {
    const styles = this.base.widgetPreset === 'about1' ? { style: { alignItems: 'center' } } : {};
    return super.ContentCard(this.merge(styles, props));
  }
}

export default Theme9;
